import { useState } from "react";
import index0 from "../images/index-0.webp";
import index1 from "../images/index-1.webp"
import potpourriHome from "../images/potpourri-home.webp";
import petFinder from "../images/pet-finder.webp"
import etcStoryTemplate from "../images/ai-writing-story.webp";
import etcHighFiveTemplate from "../images/high-five-article.webp";
import ticTacToe from "../images/tic-tac-toe.webp";
import bootStrapForm from "../images/forms.webp";
import legnthMinus2 from "../images/index-length-2.webp";
import legnthMinus1 from "../images/index-length-1.webp";
import weatherApp from "../images/weather-app.webp";
import TextBox from './TextBox'
import "../css/Gallery.css";



const Gallery = () => {
    const [index, setIndex] = useState([3, 4, 5, 6, 7]);
    //to add more projects, add the object to the array below and update the starting state to your liking.

    const galleryArray = [
        {
            source: index0,
            alt: ""
        },
        {
            source: index1,
            alt: ""
        },
        {
            source: ticTacToe,
            alt: "javascript based game",
            url: "https://2learnweb.brookhavencollege.edu/Hoobastank/logic_project/tictactoe.html",
            descripion: "This is an Xmas themed tic tac toe game that showcases my understanding of JavaScript. All winning conditions are outline in an array and event listers attached to each cell allows the game to progress based on clicks. The program works with the DOM to add classes dynamically, which in turn triggers the CSS styling with images, transformations, and cursor display changes. At the end of a game, the program activates CSS again to create a modal and display the winner as well as a restart button."
        },
        {
            source: etcStoryTemplate,
            alt: "chapter story post styling",
            url: "https://eastfieldnews.com/26951/life-arts/faculty-optimistic-about-ai-writing-tools/",
            descripion: "This is a chapter story template from the SNO content management system (CMS) which runs on WordPress. This post showcases my ability to style content and mix and match widgets and various CSS features to achieve a final product that is engaging and easy for the viewer to digest. There are about 10 different tools that were used within the SNO CMS to achieve this look, and the entire post is made up of several smaller posts wrapped in a parent container post."
        },
        {
            source: potpourriHome,
            alt: "https://www.potpourrioakcliff.com/ home page",
            url: "https://www.potpourrioakcliff.com/",
            descripion: "This is the landing page of a local tea house that I redesigned to be responsive and engaging across multiple devices. As you peruse, you see that the layout changes based on the screen size and various sections catch the eye with hover effects and well-minded transitions. The website is built with a strong focus on SEO, accessibility, and the business owner in mind. Leaning heavily into templates that Wix provided, I was able to produce a site that the owner can easily make quick updates to and change colors of the various elements based on seasonality."
        },
        {
            source: petFinder,
            alt: "pet finder application",
            url: "https://dog-finder-1c24d.web.app/dogs",
            descripion: "Pet Finder is a full stack application that runs on react.js, node.js, and mongoDB. Some of the key libraries that were used in the creation of this web app are material UI, mongoose, and express. The front-end UI is hosted on firebase, and the REST API is hosted on Google app engine (both of which are Google cloud products). This scalable project will continue to add functionality and data as it evolves. The next steps will be to add new data endpoints for the UI to communicate with and add in a secure log in feature."
        },
        {
            source: weatherApp,
            alt: "weather application",
            url: "https://robbygonzalez.github.io/weather-app/",
            descripion: "This is a weather application which I helped build with a small team during my time at the W3 schools react.js boot camp. As the name implies, it is made with react.js and utilizes weather api data from https://www.weatherapi.com/. I took the role of project manager in this assignment, which had a deadline of 2 weeks from start to finish. I made sure to start with designing the workflow and wireframe of the application, from there we were able to assign roles and create a timeline for deliverables, which helped us realized this project efficiently. Beyond the project manager duties, I also did a fair bit of the programming, being primarily responsible for the home page and I also was the lead on making the application responsive on multiple screens."
        },
        {
            source: bootStrapForm,
            alt: "styling with bootstrap library",
            url: "https://2learnweb.brookhavencollege.edu/Hoobastank/webauthoring/register.html",
            descripion: "This responsive form demonstrates my usage of CSS libraries like Bootstrap to style a page. Please interact with the form and let me know if you have any feedback. My contact information is in my footer. If you hit submit on the form, it will send your inputs to a bogus email at me@web-robby.com. The navigation menu leads to other projects I have worked on during my time at Dallas College. Although my expertise has grown since then, it is still worth a peek!"
        },
        {
            source: etcHighFiveTemplate,
            alt: "decorative post styling",
            url: "https://eastfieldnews.com/27817/life-arts/fun-things-to-do-in-mesquite-this-summer/",
            descripion: "This post was built with SNO content management system (CMS) which runs on WordPress. The styling shows a merger of a basic template along with custom CSS and HTML to create an interactive and eye-catching look. Notice how the view looks different on smaller and larger screens."
        },
        {
            source: legnthMinus2,
            alt: ""
        },
        {
            source: legnthMinus1,
            alt: ""
        }
    ]

    function handleClickLeft(e) {
        const clickedSrcLeft = e.currentTarget.src;
        const regex = /(index-1)/;
        const match = clickedSrcLeft.match(regex);
        //if the source is equal to index1
        if (match) {
            return;
        } else {
            setIndex(index.map((element) => {
                let newIndex = element - 1;
                return newIndex;
            }));
        }
    }

    function handleClickRight(e) {
        const clickedSrcRight = e.currentTarget.src;
        const regex = /(index-length-2)/;
        const match = clickedSrcRight.match(regex);
        //if the source is equal to legnthMinus2
        if (match) {
            return;
        } else {
            setIndex(index.map((element) => {
                let newIndex = element + 1;
                return newIndex;
            }));
        }
    }

    return (
        <div className="GalleryStyle">
            <img id="image2" src={galleryArray[index[1]].source} alt={galleryArray[index[1]].alt} onClick={handleClickLeft} />
            <a id="image3" href={galleryArray[index[2]].url} target="_blank" rel="noreferrer"><img id="image3Nested" src={galleryArray[index[2]].source} alt={galleryArray[index[2]].alt} /></a>
            <img id="image4" src={galleryArray[index[3]].source} alt={galleryArray[index[3]].alt} onClick={handleClickRight} />
            <TextBox
                descripion={galleryArray[index[2]].descripion}
            />
        </div>
    )
}

export default Gallery

//TODO the images without distroting, need to find a way to make them background images.
