import "../css/Gallery.css";

// eslint-disable-next-line react/prop-types
const TextBox = ({ descripion }) => {
    return (
        <div id="textBox">
            <p>{descripion}</p>
        </div>
    )
}

export default TextBox