import "./css/App.css"
import Gallery from './components/Gallery'

function App() {

  return (
    <div id="myGallery">
      <Gallery />
    </div>
  )
}

export default App